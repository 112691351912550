module.exports = {
  siteTitle: 'Progetto Oasi', // <title>
  manifestName: 'Impresa Sociale Oasi',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-eventually/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  heading: 'Oasi Trentino',
  subHeading:
    "Novità in arrivo.. per un Trentino più accogliente",
  socialLinks: [
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/oasisociale',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/impresaoasi',
    },
    /*{
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://facebook.com/oasi',
    },*/
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:info@oasi.trento.it',
    },
  ],
};
